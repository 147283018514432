import React, { useState, useEffect } from "react";

const Hero = () => {
    return (
        <div className="hero">
            {/* Layer 1 */}
            {/* This is the background with lines */}
            <Background />
            {/* Layer 2 */}
            {/* Text behind everything  */}
            <ShadowText />
            {/* Layer 3 */}
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            <Thingamajig color={"--secondary-color"} />
            <Thingamajig color={"--accent-color"} />
            {/* Layer 4 */}
            <Subtext />
            <Socials />
            <Title />
        </div>
    );
};

export default Hero;

const Thingamajig = ({ color }) => {
    const minSize = 25;
    const maxSize = 70;
    const size = Math.floor(Math.random() * (maxSize - minSize)) + minSize;
    let minFromTheTop, maxFromTheTop, minFromTheLeft, maxFromTheLeft;
    if (window.innerWidth > 1024) {
        minFromTheTop = 10;
        maxFromTheTop = 50;
        minFromTheLeft = 10;
        maxFromTheLeft = 40;
    } else {
        minFromTheTop = 0;
        maxFromTheTop = 30;
        minFromTheLeft = 0;
        maxFromTheLeft = 100;
    }
    const [position, setPosition] = useState({
        top: Math.floor(
            Math.random() * (maxFromTheTop - minFromTheTop) + minFromTheTop
        ),
        left: Math.floor(
            Math.random() * (maxFromTheLeft - minFromTheLeft) + minFromTheLeft
        ),
    });

    useEffect(() => {
        const moveAround = setInterval(() => {
            setPosition({
                top: Math.floor(
                    Math.random() * (maxFromTheTop - minFromTheTop) +
                        minFromTheTop
                ),
                left: Math.floor(
                    Math.random() * (maxFromTheLeft - minFromTheLeft) +
                        minFromTheLeft
                ),
            });
        }, 1000);

        return () => clearInterval(moveAround);
    }, [maxFromTheLeft, maxFromTheTop, minFromTheLeft, minFromTheTop]);

    const style = {
        position: "absolute",
        top: `${position.top}%`,
        left: `${position.left}%`,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `50%`,
        // borderRadius: `${size / 1.8 + 5}%`,
        border: `${size / 5}px solid var(${color})`,
        transition: "all 0.5s ease",
    };

    return <div className="thingamajig" style={style}></div>;
};

const Background = () => {
    return (
        <div className="hero__bg">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

const ShadowText = () => {
    return (
        <div className="hero__shadow-text">
            <div>WEB</div>
            <div>DEV</div>
        </div>
    );
};

const Title = () => {
    const [highlight, setHighlight] = useState(0);
    const zones = 5;

    useEffect(() => {
        const highlightTimer = setInterval(() => {
            setHighlight((prev) => {
                const curr = Math.floor(Math.random() * zones);
                if (curr === prev) {
                    return 2;
                }
                return curr;
            });
        }, 1000);

        return () => clearInterval(highlightTimer);
    }, []);

    const getClassName = (idx) => {
        if (highlight === idx) {
            const random = Math.floor(Math.random() * 2);
            if (random === 0) return "accented";
            if (random === 1) return "secondaried";
        }
    };

    return (
        <div className="hero__content">
            <div className="title">
                <div>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        H
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        e
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        l
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        l
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        o
                    </span>
                </div>
                <div>I am</div>
                <div>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        H
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        a
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        t
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        a
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        f
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        {" "}
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        A
                    </span>
                    <span
                        className={getClassName(
                            Math.floor(Math.random() * zones)
                        )}
                    >
                        .
                    </span>
                </div>
            </div>
        </div>
    );
};

const Socials = () => {
    return (
        <div className="hero__socials">
            <div></div>
            <div><a href="https://linkedin.com/in/hatafatif">LinkedIn</a></div>
            <div>Email</div>
            <div><a href="https://github.com/hatafatif">Github</a></div>
            <div></div>
        </div>
    );
};

const Subtext = () => {
    return (
        <div className="hero__subtext">
            <div>
                <div>Full Stack Developer</div>
                <div>Biz. Intelligence Analyst</div>
                <div>3 years of experience</div>
            </div>
        </div>
    );
};
