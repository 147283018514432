import React from "react";
import Slider from "react-slick";
import "./LogoSlider.css"

const LogoSlider = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        variableWidth: true,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: false,
        speed: 2500,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: true
    };

    return (
        <div className="logo-slider">
        <div className="slider-wrapper">

            <Slider {...settings}>
                {logos?.map((logo, index) => (
                    <div key={index} className="logo-card">
                        <img
                            src={logo.url}
                            alt={logo.name}
                            width={100}
                            height={57}
                            style={{objectFit: "contain" }}
                        />
                    </div>
                ))}
            </Slider>
        </div>
        </div>
    );
};

export default LogoSlider;

const logos = [
    {
        name: "JavaScript",
        url: "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
    },
    {
        name: "React",
        url: "https://logos-world.net/wp-content/uploads/2023/08/React-Symbol.png",
    },
    {
        name: "Python",
        url: "https://logos-world.net/wp-content/uploads/2021/10/Python-Symbol.png",
    },
    {
        name: "NodeJS",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/590px-Node.js_logo.svg.png",
    },
    {
        name: "NextJS",
        url: "https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png",
    },
    {
        name: "Django",
        url: "https://download.logo.wine/logo/Django_(web_framework)/Django_(web_framework)-Logo.wine.png",
    },
    {
        name: "Flask",
        url: "https://cdn.icon-icons.com/icons2/2699/PNG/512/pocoo_flask_logo_icon_168045.png",
    },
    {
        name: "pandas",
        url: "https://pandas.pydata.org/static/img/pandas_secondary.svg",
    },
    {
        name: "PowerBI",
        url: "https://logohistory.net/wp-content/uploads/2023/05/Power-BI-Symbol.png",
    },
     {
        name: "AWS",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png",
    },
    {
        name: "Spring",
        url: "https://miro.medium.com/v2/resize:fit:700/0*R60lnmJl4hanOBaJ.png",
    },
    {
        name: "Kotlin",
        url: "https://logowik.com/content/uploads/images/kotlin.jpg",
    },
    {
        name: "Wordpress",
        url: "https://upload.wikimedia.org/wikipedia/commons/9/93/Wordpress_Blue_logo.png",
    },
    {
        name: "Woocommerce",
        url: "https://www.zoomcouriersoftware.com/wp-content/uploads/2023/07/WooCommerce-Logo.png",
    },
    {
        name: "Shopify",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Shopify_Logo.png/640px-Shopify_Logo.png",
    },
];



    
   
