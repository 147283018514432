import "./App.css"
import Hero from "./components/Hero"
import LogoSlider from "./components/LogoSlider/LogoSlider";

const App = () => {
    return (
        <div className="app">
           <Hero /> 
           <LogoSlider />
        </div>
    );
};

export default App;
